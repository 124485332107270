import { Skeleton } from '@mui/material';
import { type JSX, useState } from 'react';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { Text } from 'Components/Text/Text';
import { ComplianceSummary } from 'Models/Dashboards';

import { ComplianceCarousel } from './ComplianceCarousel/ComplianceCarousel';
import { ComplianceRequirementDisplayInformation, ComplianceRequirementDisplayModal } from '../ComplianceRequirements/ComplianceRequirementDisplayModal/ComplianceRequirementDisplayModal';
import { stringToComplianceModalType } from '../OperationalControlsDashboard.helpers';

export interface RequirementsOverviewProps {
    complianceSummaries?: ComplianceSummary[];
    complianceSummaryError?: Error;
    api: DashboardApi;
}

export const RequirementsOverview = (props: RequirementsOverviewProps): JSX.Element => {
    const [displayModalProps, setDisplayModalProps] = useState<ComplianceRequirementDisplayInformation>();

    const handleChartClick = (item: string, regulation: string): void => {
        if (stringToComplianceModalType(item)) {
            const regulationTypeSelected = stringToComplianceModalType(item);
            setDisplayModalProps({ regulationName: regulation, regulationTypeSelected: regulationTypeSelected! });
        }
    };

    if (props.complianceSummaryError) {
        return (
            <Text variant="Header4" color="lightGray">
                {props.complianceSummaryError.message}
            </Text>
        );
    } else {
        return (
            <>
                {displayModalProps && <ComplianceRequirementDisplayModal hideModal={() => setDisplayModalProps(undefined)} regulationInformation={displayModalProps} api={props.api} />}
                {props.complianceSummaries ? (
                    <ComplianceCarousel complianceSummaries={props.complianceSummaries} handleChartClick={handleChartClick} />
                ) : (
                    <>
                        <Skeleton variant="text">
                            <Text variant="Text2" color="darkGray">
                                Regulation 1
                            </Text>
                        </Skeleton>
                        <Skeleton height={50} width={'100%'} variant="rounded" />
                        <Skeleton variant="text">
                            <Text variant="Text2" color="darkGray">
                                Regulation 2
                            </Text>
                        </Skeleton>
                        <Skeleton height={50} width={'100%'} variant="rounded" />
                        <Skeleton variant="text">
                            <Text variant="Text2" color="darkGray">
                                Regulation 3
                            </Text>
                        </Skeleton>
                        <Skeleton height={50} width={'100%'} variant="rounded" />
                    </>
                )}
            </>
        );
    }
};
