import { type JSX, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { IconButton } from 'Components/Buttons/IconButton';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { ICON_CLOSE, ICON_DELETE_REMOVE } from 'Config/Icons';
import { TPRM_IRQ_TARGET_COMPLETION_DATE_UPDATE_SERVICE_MODAL } from 'Config/Tooltips';
import { iso8601ToJsDate, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { SaveThirdPartyServiceRequest, Service } from 'Models/TPRM';

import styles from './ServiceInherentRiskQuestionnaireTargetCompletionDateModal.module.css';

export interface ServiceInherentRiskQuestionnaireTargetCompletionDateModalProps {
    hideModal: () => void;
    service: Service;
    targetDateSet: () => void;
    tprmApi: TPRMApi;
}

interface FormState {
    isLoading?: boolean;
    successMessage?: string;
    failureMessage?: string;
}

// TODO [TPRM - Service Dashboard]: DRY this with the UI/logic in ServiceDashboard for the start/restart modals, if possible. Lots was copied from there and pasted here.
export const ServiceInherentRiskQuestionnaireTargetCompletionDateModal = (props: ServiceInherentRiskQuestionnaireTargetCompletionDateModalProps): JSX.Element => {
    const [formState, setFormState] = useState<FormState>({});
    const [selectedIrqTargetCompletionDate, setSelectedIrqTargetCompletionDate] = useState<Date | undefined>(props.service.assessment_workflow_setup.irq_target_completion_date ? iso8601ToJsDate(props.service.assessment_workflow_setup.irq_target_completion_date) : undefined);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            setFormState({ isLoading: true });
            const request: SaveThirdPartyServiceRequest = {
                name: props.service.name,
                description: props.service.description,
                vendor_service_manager_user_id: props.service.vendor_service_manager_user_id,
                assessment_due_date: props.service.assessment_workflow_setup.due_date,
                delegates: props.service.delegates,
                irq_target_completion_date: selectedIrqTargetCompletionDate ? jsDateToIso8601(selectedIrqTargetCompletionDate) : undefined,
                responsible_organization: props.service.responsible_organization,
                tags: props.service.tags,
                vendor_contacts: props.service.vendor_contacts,
            };
            await props.tprmApi.saveThirdPartyService(request, props.service.vendor_id, props.service.id);
            props.targetDateSet();
            setFormState({ successMessage: 'Inherent Risk Questionnaire target completion date saved.', isLoading: false });
        } catch (error) {
            handleRequestError(error);
        }
    };

    const handleRequestError = (error: Error) => {
        setFormState({ failureMessage: error.message, isLoading: false });
    };

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Schedule Inherent Risk Questionnaire" />

                    <div className={styles.datePickerAndClearIcon}>
                        <FormFieldDatePicker dateFormat="MM/dd/yyyy" selected={selectedIrqTargetCompletionDate} handleChange={setSelectedIrqTargetCompletionDate} formFieldId="nextAssessmentDate" formFieldLabel="Inherent Risk Questionnaire Target Completion Date" placeholder={'MM/DD/YYYY'} invalidMessage={'Please enter a valid date (MM/DD/YYYY)'} tooltip={TPRM_IRQ_TARGET_COMPLETION_DATE_UPDATE_SERVICE_MODAL} />
                        <div className={styles.clearIcon}>
                            <IconButton aria-label="clear-date" onClick={() => setSelectedIrqTargetCompletionDate(undefined)} fontAwesomeImage={ICON_DELETE_REMOVE} />
                        </div>
                    </div>

                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={ICON_CLOSE}>
                            Close
                        </Button>
                        <Button variant="submit" isLoading={formState.isLoading === true} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
