import { cloneDeep, isUndefined } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Button } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { PageLayoutDefaultGridAndTable } from 'Components/PageLayout/PageLayoutDefaultGridAndTable';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Text } from 'Components/Text/Text';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { ICON_ADD_CREATE } from 'Config/Icons';
import { THIRD_PARTY_ID } from 'Config/Paths';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { undefinedComparator } from 'Helpers/Compare';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { InherentRiskFilterOptionTypes, ResidualRiskFilterOptionTypes, Service, ServiceListingSortFilterOptions, ThirdPartyResponse, ThirdPartyResponseWithServices } from 'Models/TPRM';
import { Filter, Filters, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';

import { DeleteTPRMServiceModal, DeleteTPRMServiceModalProps } from './DeleteTPRMServiceModal/DeleteTPRMServiceModal';
import styles from './ManageTPRMServices.module.css';
import { SaveTPRMThirdPartyServiceModal, SaveTPRMThirdPartyServiceModalProps } from './SaveTPRMThirdPartyServiceModal/SaveTPRMThirdPartyServiceModal';
import { ServiceAssessmentDueDateModal, ServiceAssessmentDueDateModalProps } from '../ServiceAssessmentDueDateModal/ServiceAssessmentDueDateModal';
import { ServiceInherentRiskQuestionnaireTargetCompletionDateModal, ServiceInherentRiskQuestionnaireTargetCompletionDateModalProps } from '../ServiceInherentRiskQuestionnaireTargetCompletionDateModal/ServiceInherentRiskQuestionnaireTargetCompletionDateModal';
import { ServiceCard } from './ServiceListing/ServiceCard/ServiceCard';
import { ServiceListingBodyToolbar, ServiceListingBodyToolbarProps } from './ServiceListing/ServiceListingBodyToolbar/ServiceListingBodyToolbar';
import { ServiceListingTableView, ServiceListingTableViewProps } from './ServiceListing/ServiceListingTableView/ServiceListingTableView';

export interface ManageTPRMServicesProps {
    tagsApi: TagsApi;
    tprmApi: TPRMApi;
}

export enum Modals {
    DeleteTPRMServiceModal,
    SaveTPRMThirdPartyServiceModal,
    ServiceAssessmentDueDateModal,
    ServiceIrqTargetCompletionDateModal,
    None,
}

export interface ManageTPRMServicesRouteState {
    createServiceForThirdParty?: ThirdPartyResponseWithServices;
}

export const ManageTPRMServices = (props: ManageTPRMServicesProps) => {
    const cachedData = useCachedData();
    const location = useLocation();
    const navigate = useNavigate();

    const [currentSort, setCurrentSort] = useState<string>(ServiceListingSortFilterOptions.NAME);
    const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(SortDirection.ASC);
    const [defaultThirdPartyByWhichToFilter, setDefaultThirdPartyByWhichToFilter] = useState<ThirdPartyResponseWithServices>();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);

    // The "filter by" options selected by the user either via this page, or via a "View Services" button on the Manage Third Parties page that links here.
    // By the time the page actually renders (because the list of third parties has been fetched), this will be in sync with `defaultThirdPartyByWhichToFilter`.
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>(() => {
        const searchParams = new URLSearchParams(location.search);
        const thirdPartyId = searchParams.get(THIRD_PARTY_ID);

        const filters: Filters = thirdPartyId
            ? {
                  vendor_id: {
                      key: 'vendor_id',
                      value: [thirdPartyId],
                  },
              }
            : {};

        return filters;
    });
    const [selectedService, setSelectedService] = useState<Service>();
    const [selectedThirdParty, setSelectedThirdParty] = useState<ThirdPartyResponseWithServices>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>(false);
    const [thirdParties, setThirdParties] = useState<ThirdPartyResponseWithServices[]>();

    const getThirdParties = useCallback(async (): Promise<void> => {
        try {
            const thirdPartyResponse = await props.tprmApi.getThirdParties();
            const thirdParties = thirdPartyResponse.data;
            setThirdParties(thirdParties);

            const searchParams = new URLSearchParams(location.search);
            const thirdPartyId = searchParams.get(THIRD_PARTY_ID);
            const defaultThirdPartyByWhichToFilter = thirdPartyId ? thirdParties.find((thirdParty) => thirdParty.id === thirdPartyId) : undefined;
            setDefaultThirdPartyByWhichToFilter(defaultThirdPartyByWhichToFilter);
        } catch (error) {
            handleRequestError(error);
        }
    }, [location.search, props.tprmApi]);

    useEffect(() => {
        getThirdParties();

        if (location.state && (location.state as ManageTPRMServicesRouteState).createServiceForThirdParty) {
            // Prevent showing the modal every time the page is reloaded.
            navigate(location.pathname, { state: {}, replace: true });

            setDisplayedModal(Modals.SaveTPRMThirdPartyServiceModal);
            setSelectedThirdParty((location.state as ManageTPRMServicesRouteState).createServiceForThirdParty);
            setTprmAccessDenied(false);
        }
    }, [getThirdParties, location.pathname, location.state, navigate]);

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const hideModal = (): void => {
        setDisplayedModal(Modals.None);
        setSelectedService(undefined);
        setSelectedThirdParty(undefined);
    };

    const handleRequestError = (error: Error): void => {
        if (isForbiddenResponseError(error)) {
            setTprmAccessDenied(true);
        } else {
            console.error('Error: ', error);
        }
    };

    const setCurrentSortAndDirection = (newSort: string, newSortDirection: SortDirection): void => {
        setCurrentSort(newSort as ServiceListingSortFilterOptions);
        setCurrentSortDirection(newSortDirection);
    };

    const displayModalWithService = (modal: Modals, selectedService: Service): void => {
        if (thirdParties) {
            const service = thirdParties.find((v) => v.id === selectedService.vendor_id)?.services.find((s) => s.id === selectedService.id);
            setSelectedService(service);
            setDisplayedModal(modal);
        }
    };

    const getDeleteTPRMServiceModalProps = (newlySelectedService: Service): DeleteTPRMServiceModalProps => {
        const deleteTPRMServiceModalProps: DeleteTPRMServiceModalProps = {
            hideModal: hideModal,
            serviceDeleted: () => getThirdParties(),
            tprmApi: props.tprmApi,
            service: newlySelectedService,
        };

        return deleteTPRMServiceModalProps;
    };

    const getSetTPRMServiceDueDateModalProps = (newlySelectedService: Service): ServiceAssessmentDueDateModalProps => {
        return {
            hideModal: hideModal,
            serviceAssessmentDueDateSet: () => getThirdParties(),
            tprmApi: props.tprmApi,
            service: newlySelectedService,
        };
    };

    const getSetTPRMInherentRiskQuestionnaireTargetCompletionDateModalProps = (newlySelectedService: Service): ServiceInherentRiskQuestionnaireTargetCompletionDateModalProps => {
        return {
            hideModal: hideModal,
            targetDateSet: () => getThirdParties(),
            tprmApi: props.tprmApi,
            service: newlySelectedService,
        };
    };

    const createServiceListing = (thirdParties: ThirdPartyResponseWithServices[]): Service[] => {
        return thirdParties.flatMap((thirdParty) => {
            return thirdParty.services.map((service) => {
                return service;
            });
        });
    };

    const setCurrentFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                currentFilterList[updatedFilter.key] = updatedFilter;
            } else {
                delete currentFilterList[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(currentFilterList);
    };

    const createFilterOptions = (thirdParties: ThirdPartyResponse[]): GroupedOptions[] => {
        const thirdPartyOptions: GroupOptionType[] = [];
        thirdParties.forEach((thirdParty) => {
            thirdPartyOptions.push({
                groupId: ServiceListingSortFilterOptions.VENDOR_ID,
                label: thirdParty.name,
                value: thirdParty.id,
            });
        });
        const filterOptions: GroupedOptions[] = [
            {
                label: 'Third Parties',
                options: thirdPartyOptions,
            },
            {
                label: 'Service Inherent Risk',
                options: InherentRiskFilterOptionTypes,
            },
            {
                label: 'Service Residual Risk',
                options: ResidualRiskFilterOptionTypes,
            },
            {
                label: 'Assessment Due Date',
                options: [
                    {
                        groupId: ServiceListingSortFilterOptions.DUE_DATE,
                        label: 'Set',
                        value: 1,
                    },
                    {
                        groupId: ServiceListingSortFilterOptions.DUE_DATE,
                        label: 'Not Set',
                        value: 0,
                    },
                ],
            },
        ];
        return filterOptions;
    };

    const filterAndSortThirdPartyServices = (thirdPartyServices: Service[]): Service[] => {
        let filteredAndSortedThirdPartyServices = thirdPartyServices;

        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                filteredAndSortedThirdPartyServices = filteredAndSortedThirdPartyServices.filter((service) => {
                    if (filter.key === ServiceListingSortFilterOptions.DUE_DATE) {
                        // Filters can only be numbers or strings. 1 is Set 0 is Not Set
                        return (filter.value.includes(1) && !isUndefined(service.assessment_workflow_setup.due_date)) || (filter.value.includes(0) && isUndefined(service.assessment_workflow_setup.due_date));
                    } else if (filter.key in service) {
                        return filter.value.includes((service as any)[filter.key]);
                    } else {
                        return false;
                    }
                });
            }
        }

        if (!currentSort) {
            return filteredAndSortedThirdPartyServices;
        }
        return filteredAndSortedThirdPartyServices.sort((serviceA, serviceB) => {
            let sortResult = 0;

            // To future developer: Before you try to refactor this into a switch statement, note that it can't be done (that I'm aware of) since "due_date" is nested within the "assessment_workflow_setup" attribute of Service.
            if (currentSort === ServiceListingSortFilterOptions.INHERENT_RISK_SCORE || currentSort === ServiceListingSortFilterOptions.RESIDUAL_RISK_SCORE) {
                const riskA = serviceA[currentSort as keyof Service];
                const riskB = serviceB[currentSort as keyof Service];
                if (riskA === riskB) {
                    sortResult = 0;
                } else if (riskA !== undefined && riskB !== undefined && riskA > riskB) {
                    sortResult = 1;
                } else {
                    sortResult = -1;
                }
            } else if (currentSort === ServiceListingSortFilterOptions.VENDOR_SERVICE_MANAGER_USER_ID) {
                sortResult = compareUsersBySubjectForSorting(serviceA.vendor_service_manager_user_id, serviceB.vendor_service_manager_user_id, cachedData.users);
            } else if (currentSort === ServiceListingSortFilterOptions.CREATED_TIME) {
                sortResult = serviceA.created_time < serviceB.created_time ? 1 : -1;
            } else if (currentSort === ServiceListingSortFilterOptions.DUE_DATE) {
                sortResult = undefinedComparator(serviceA.assessment_workflow_setup.due_date, serviceB.assessment_workflow_setup.due_date, (assessmentDueDateA, assessmentDueDateB) => (assessmentDueDateA > assessmentDueDateB ? 1 : -1));
            } else {
                sortResult = (serviceA[currentSort as keyof Service] as string).localeCompare(serviceB[currentSort as keyof Service] as string);
            }

            return currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const getDefaultSelectedOptions = (): GroupOptionType[] | undefined => {
        if (defaultThirdPartyByWhichToFilter) {
            return [
                {
                    groupId: ServiceListingSortFilterOptions.VENDOR_ID,
                    label: defaultThirdPartyByWhichToFilter.name,
                    value: defaultThirdPartyByWhichToFilter.id,
                },
            ];
        }

        return undefined;
    };

    if (tprmAccessDenied) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>{UNAUTHORIZED_MESSAGE}</Text>
            </div>
        );
    }

    if (thirdParties) {
        const services = createServiceListing(thirdParties);
        const filteredAndSortedThirdPartyServices = filterAndSortThirdPartyServices(services);

        const saveTPRMThirdPartyServiceModalProps: SaveTPRMThirdPartyServiceModalProps = {
            defaultThirdParty: selectedThirdParty,
            hideModal: hideModal,
            tagsApi: props.tagsApi,
            tprmApi: props.tprmApi,
            thirdParties: thirdParties,
            thirdPartyService: selectedService,
            thirdPartyServiceSaved: () => getThirdParties(),
        };

        const serviceListingBodyToolbarProps: ServiceListingBodyToolbarProps = {
            filterOptions: createFilterOptions(thirdParties),
            defaultSelectedOptions: getDefaultSelectedOptions(),
            thirdPartyFilter: setCurrentFilterSelection,
            sortCardsBy: setCurrentSortAndDirection,
            currentSort: currentSort,
        };

        const serviceListingTableViewProps: ServiceListingTableViewProps = {
            thirdPartyServices: filteredAndSortedThirdPartyServices,
            setSortColumnAndDirection: setCurrentSortAndDirection,
            currentSort: currentSort,
            currentSortDirection: currentSortDirection,
            selectedDeleteService: (service: Service) => displayModalWithService(Modals.DeleteTPRMServiceModal, service),
            selectedModifyService: (service: Service) => displayModalWithService(Modals.SaveTPRMThirdPartyServiceModal, service),
            selectedServiceAssessmentDueDateService: (service: Service) => displayModalWithService(Modals.ServiceAssessmentDueDateModal, service),
            selectedSetInherentRiskQuestionnaireTargetCompletionDate: (service: Service) => displayModalWithService(Modals.ServiceIrqTargetCompletionDateModal, service),
        };

        return (
            <>
                {displayedModal === Modals.DeleteTPRMServiceModal && selectedService && <DeleteTPRMServiceModal {...getDeleteTPRMServiceModalProps(selectedService)} />}
                {displayedModal === Modals.SaveTPRMThirdPartyServiceModal && <SaveTPRMThirdPartyServiceModal {...saveTPRMThirdPartyServiceModalProps} />}
                {displayedModal === Modals.ServiceAssessmentDueDateModal && selectedService && <ServiceAssessmentDueDateModal {...getSetTPRMServiceDueDateModalProps(selectedService)} />}
                {displayedModal === Modals.ServiceIrqTargetCompletionDateModal && selectedService && <ServiceInherentRiskQuestionnaireTargetCompletionDateModal {...getSetTPRMInherentRiskQuestionnaireTargetCompletionDateModalProps(selectedService)} />}

                <PageLayoutDefaultGridAndTable
                    headerTitle="Manage Third-Party Services"
                    headerButtons={
                        thirdParties.length > 0 && (
                            <Button variant="primary" onClick={() => displayModal(Modals.SaveTPRMThirdPartyServiceModal)} fontAwesomeImage={ICON_ADD_CREATE}>
                                Create New Service
                            </Button>
                        )
                    }
                    bodyFilters={<ServiceListingBodyToolbar {...serviceListingBodyToolbarProps} />}
                    bodyCards={filteredAndSortedThirdPartyServices.map((thirdPartyService) => (
                        <ServiceCard key={thirdPartyService.id} thirdPartyService={thirdPartyService} selectedDeleteService={(service: Service) => displayModalWithService(Modals.DeleteTPRMServiceModal, service)} selectedModifyService={(service: Service) => displayModalWithService(Modals.SaveTPRMThirdPartyServiceModal, service)} selectedServiceAssessmentDueDateService={(service: Service) => displayModalWithService(Modals.ServiceAssessmentDueDateModal, service)} selectedSetInherentRiskQuestionnaireTargetCompletionDate={(service: Service) => displayModalWithService(Modals.ServiceIrqTargetCompletionDateModal, service)} />
                    ))}
                    bodyTable={<ServiceListingTableView {...serviceListingTableViewProps} />}
                />
            </>
        );
    }

    return <Placeholder />;
};
